/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://n7muzrljifaqldnef2nm4w5zpi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-iofbsksenbcn3onr2zwix3spe4",
    "aws_cognito_identity_pool_id": "us-east-1:ce440d77-1843-402a-8d31-987726a90c96",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_RX4oSlzuo",
    "aws_user_pools_web_client_id": "7sihsiancojh07f68p5a59f6pu",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_mobile_analytics_app_id": "655b5366436942849f4b6d2ae3e4e95f",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
