const programs: Record<string, string> = {
  "Multi-/Interdisciplinary Studies, General.": "Miscellaneous",
  "English Language and Literature, General.":
    "English, Liberal Arts, and Humanities",
  "Multi/Interdisciplinary Studies, Other.": "Miscellaneous",
  "Fine and Studio Arts.": "Visual Arts and Music",
  "Social Work.": "Public Administration and Human Services",
  "Teacher Education and Professional Development, Specific Levels and Methods.":
    "Education",
  "Mathematics.": "Mathematics and Statistics",
  "Dance.": "Visual Arts and Music",
  "Health and Physical Education/Fitness.":
    "Public Administration and Human Services",
  "Political Science and Government.": "Social Sciences excluding Economics",
  "History.": "English, Liberal Arts, and Humanities",
  "Communication and Media Studies.": "Communications and Journalism",
  "Business Administration, Management and Operations.":
    "Business and Management",
  "Security Science and Technology.":
    "Public Administration and Human Services",
  "Psychology, General.": "Psychology",
  "Drama/Theatre Arts and Stagecraft.": "Visual Arts and Music",
  "Sociology.": "Social Sciences excluding Economics",
  "Registered Nursing, Nursing Administration, Nursing Research and Clinical Nursing.":
    "Health and Nursing",
  "Chemistry.": "Physical Sciences",
  "Computer and Information Sciences, General.":
    "Computer and Information Sciences",
  "Philosophy.": "Philosophy and Religious Studies",
  "Atmospheric Sciences and Meteorology.": "Physical Sciences",
  "Physiology, Pathology and Related Sciences.": "Life Sciences and Biology",
  "Anthropology.": "Social Sciences excluding Economics",
  "Liberal Arts and Sciences, General Studies and Humanities.":
    "English, Liberal Arts, and Humanities",
  "Mechanical Engineering.": "Engineering",
  "Non-Professional General Legal Studies (Undergraduate).": "Miscellaneous",
  "Film/Video and Photographic Arts.": "Visual Arts and Music",
  "Management Sciences and Quantitative Methods.": "Business and Management",
  "Geography and Cartography.": "Social Sciences excluding Economics",
  "Accounting and Related Services.": "Business and Management",
  "Health and Medical Administrative Services.": "Health and Nursing",
  "Design and Applied Arts.": "Visual Arts and Music",
  "Environmental/Environmental Health Engineering.": "Engineering",
  "Agricultural Business and Management.": "Agriculture and Natural Resources",
  "Journalism.": "Communications and Journalism",
  "Computer Science.": "Computer and Information Sciences",
  "Electrical, Electronics and Communications Engineering.": "Engineering",
  "Criminal Justice and Corrections.":
    "Public Administration and Human Services",
  "Biology, General.": "Life Sciences and Biology",
  "Public Relations, Advertising, and Applied Communication.":
    "Communications and Journalism",
  "Ethnic, Cultural Minority, Gender, and Group Studies.":
    "Social Sciences excluding Economics",
  "Clinical/Medical Laboratory Science/Research and Allied Professions.":
    "Health and Nursing",
  "Public Health.": "Health and Nursing",
  "Computer and Information Sciences and Support Services, Other.":
    "Computer and Information Sciences",
  "Religion/Religious Studies.": "Philosophy and Religious Studies",
  "Romance Languages, Literatures, and Linguistics.": "Miscellaneous",
  "Architecture.": "Transportation, Construction, and Architecture",
  "Management Information Systems and Services.": "Business and Management",
  "Industrial Production Technologies/Technicians.": "Engineering",
  "Geological and Earth Sciences/Geosciences.": "Physical Sciences",
  "Engineering Science.": "Engineering",
  "Real Estate.": "Business and Management",
  "Agricultural Engineering.": "Engineering",
  "Allied Health Diagnostic, Intervention, and Treatment Professions.":
    "Health and Nursing",
  "Linguistic, Comparative, and Related Language Studies and Services.":
    "Miscellaneous",
  "Ecology, Evolution, Systematics, and Population Biology.":
    "Life Sciences and Biology",
  "Computer Engineering.": "Engineering",
  "Marketing.": "Business and Management",
  "Music.": "Visual Arts and Music",
  "Health Services/Allied Health/Health Sciences, General.":
    "Health and Nursing",
  "Human Resources Management and Services.": "Business and Management",
  "Rhetoric and Composition/Writing Studies.":
    "English, Liberal Arts, and Humanities",
  "Physics.": "Physical Sciences",
  "Nutrition Sciences.": "Miscellaneous",
  "Architectural Sciences and Technology.":
    "Transportation, Construction, and Architecture",
  "Teacher Education and Professional Development, Specific Subject Areas.":
    "Education",
  "Civil Engineering.": "Engineering",
  "Information Science/Studies.": "Computer and Information Sciences",
  "Wildlife and Wildlands Science and Management.":
    "Agriculture and Natural Resources",
  "Biochemistry, Biophysics and Molecular Biology.":
    "Life Sciences and Biology",
  "Human Development, Family Studies, and Related Services.": "Miscellaneous",
  "Family and Consumer Sciences/Human Sciences, General.": "Miscellaneous",
  "Business/Managerial Economics.": "Business and Management",
  "Radio, Television, and Digital Communication.":
    "Communications and Journalism",
  "Computer Programming.": "Computer and Information Sciences",
  "Industrial Engineering.": "Engineering",
  "Behavioral Sciences.": "Miscellaneous",
  "Classical and Ancient Studies.": "Miscellaneous",
  "Cell/Cellular Biology and Anatomical Sciences.": "Life Sciences and Biology",
  "Area Studies.": "Social Sciences excluding Economics",
  "Communication Disorders Sciences and Services.": "Health and Nursing",
  "Business/Commerce, General.": "Business and Management",
  "Engineering, General.": "Engineering",
  "Religious Education.": "Philosophy and Religious Studies",
  "Bible/Biblical Studies.": "Philosophy and Religious Studies",
  "Intercultural/Multicultural and Diversity Studies.": "Miscellaneous",
  "International Business.": "Business and Management",
  "Urban Studies/Affairs.": "Social Sciences excluding Economics",
  "Forestry.": "Agriculture and Natural Resources",
  "Public Policy Analysis.": "Public Administration and Human Services",
  "Plant Sciences.": "Agriculture and Natural Resources",
  "Economics.": "Economics",
  "Parks, Recreation and Leisure Studies.":
    "Public Administration and Human Services",
  "Psychology, Other.": "Psychology",
  "Criminology.": "Social Sciences excluding Economics",
  "Special Education and Teaching.": "Education",
  "Finance and Financial Management Services.": "Business and Management",
  "Educational Assessment, Evaluation, and Research.": "Education",
  "Landscape Architecture.": "Transportation, Construction, and Architecture",
  "Communication, Journalism, and Related Programs, Other.":
    "Communications and Journalism",
  "Pastoral Counseling and Specialized Ministries.":
    "Philosophy and Religious Studies",
  "Social Sciences, General.": "Social Sciences excluding Economics",
  "Air Transportation.": "Transportation, Construction, and Architecture",
  "Dietetics and Clinical Nutrition Services.": "Health and Nursing",
  "Engineering, Other.": "Engineering",
  "Natural Resources Conservation and Research.":
    "Agriculture and Natural Resources",
  "Apparel and Textiles.": "Miscellaneous",
  "Construction Engineering Technologies.": "Engineering",
  "Applied Mathematics.": "Mathematics and Statistics",
  "Housing and Human Environments.": "Miscellaneous",
  "Health Professions and Related Clinical Sciences, Other.":
    "Health and Nursing",
  "Engineering Technologies/Technicians, Other.": "Engineering",
  "East Asian Languages, Literatures, and Linguistics.": "Miscellaneous",
  "Research and Experimental Psychology.": "Psychology",
  "International Relations and National Security Studies.":
    "Social Sciences excluding Economics",
  "Biomedical/Medical Engineering.": "Engineering",
  "Graphic Communications.": "Communications and Journalism",
  "Teaching English or French as a Second or Foreign Language.": "Education",
  "Quality Control and Safety Technologies/Technicians.": "Engineering",
  "Human Services, General.": "Public Administration and Human Services",
  "Operations Research.": "Engineering",
  "City/Urban, Community and Regional Planning.":
    "Transportation, Construction, and Architecture",
  "Culinary Arts and Related Services.": "Miscellaneous",
  "Health/Medical Preparatory Programs.": "Health and Nursing",
  "Mechanical Engineering Related Technologies/Technicians.": "Engineering",
  "Entrepreneurial and Small Business Operations.": "Business and Management",
  "Data Processing.": "Computer and Information Sciences",
  "Foods, Nutrition, and Related Services.": "Miscellaneous",
  "Dental Support Services and Allied Professions.": "Health and Nursing",
  "Parks, Recreation, Leisure, and Fitness Studies, Other.":
    "Public Administration and Human Services",
  "Aerospace, Aeronautical and Astronautical Engineering.": "Engineering",
  "Legal Support Services.": "Miscellaneous",
  "Chemical Engineering.": "Engineering",
  "Education, Other.": "Education",
  "Microbiological Sciences and Immunology.": "Life Sciences and Biology",
  "Public Administration and Social Service Professions, Other.":
    "Public Administration and Human Services",
  "Somatic Bodywork and Related Therapeutic Services.": "Health and Nursing",
  "Engineering-Related Fields.": "Engineering",
  "Electrical Engineering Technologies/Technicians.": "Engineering",
  "Theological and Ministerial Studies.": "Philosophy and Religious Studies",
  "Visual and Performing Arts, General.": "Visual Arts and Music",
  "Public Administration.": "Public Administration and Human Services",
  "Social Sciences, Other.": "Social Sciences excluding Economics",
  "Homeland Security.": "Public Administration and Human Services",
  "Education, General.": "Education",
  "Biological/Biosystems Engineering.": "Engineering",
  "Clinical, Counseling and Applied Psychology.": "Psychology",
  "Specialized Sales, Merchandising and  Marketing Operations.":
    "Business and Management",
  "Audiovisual Communications Technologies/Technicians.":
    "Communications and Journalism",
  "Mental and Social Health Services and Allied Professions.":
    "Health and Nursing",
  "Fire Protection.": "Public Administration and Human Services",
  "Materials Sciences.": "Physical Sciences",
  "Engineering Technology, General.": "Engineering",
  "Animal Sciences.": "Agriculture and Natural Resources",
  "Classics and Classical Languages, Literatures, and Linguistics.":
    "Miscellaneous",
  "Agriculture, General.": "Agriculture and Natural Resources",
  "Statistics.": "Mathematics and Statistics",
  "Curriculum and Instruction.": "Education",
  "Biological and Biomedical Sciences, Other.": "Life Sciences and Biology",
  "Science Technologies/Technicians, Other.": "Physical Sciences",
  "Petroleum Engineering.": "Engineering",
  "Gerontology.": "Miscellaneous",
  "Interior Architecture.": "Transportation, Construction, and Architecture",
  "Rehabilitation and Therapeutic Professions.": "Health and Nursing",
  "Missions/Missionary Studies and Missiology.":
    "Philosophy and Religious Studies",
  "Business/Corporate Communications.": "Business and Management",
  "Energy Systems Technologies/Technicians.": "Engineering",
  "Systems Engineering.": "Engineering",
  "Theology and Religious Vocations, Other.":
    "Philosophy and Religious Studies",
  "Construction Management.": "Business and Management",
  "Community Organization and Advocacy.":
    "Public Administration and Human Services",
  "Religious/Sacred Music.": "Philosophy and Religious Studies",
  "Cognitive Science.": "Miscellaneous",
  "International/Global Studies.": "Miscellaneous",
  "General Sales, Merchandising and Related Marketing Operations.":
    "Business and Management",
  "Food Science and Technology.": "Agriculture and Natural Resources",
  "Insurance.": "Business and Management",
  "Engineering-Related Technologies.": "Engineering",
  "Materials Engineering": "Engineering",
  "Agricultural Public Services.": "Agriculture and Natural Resources",
  "Germanic Languages, Literatures, and Linguistics.": "Miscellaneous",
  "Computer/Information Technology Administration and Management.":
    "Computer and Information Sciences",
  "Engineering Physics.": "Engineering",
  "Marine Transportation.": "Transportation, Construction, and Architecture",
  "Applied Horticulture and Horticultural Business Services.":
    "Agriculture and Natural Resources",
  "Hospitality Administration/Management.": "Business and Management",
  "Cultural Studies/Critical Theory and Analysis.": "Miscellaneous",
  "International Agriculture.": "Agriculture and Natural Resources",
  "Business, Management, Marketing, and Related Support Services, Other.":
    "Business and Management",
  "Pharmacy, Pharmaceutical Sciences, and Administration.":
    "Health and Nursing",
  "Peace Studies and Conflict Resolution.": "Miscellaneous",
  "Manufacturing Engineering.": "Engineering",
  "Literature.": "English, Liberal Arts, and Humanities",
  "Biotechnology.": "Life Sciences and Biology",
  "Computer Systems Networking and Telecommunications.":
    "Computer and Information Sciences",
  "Computer Software and Media Applications.":
    "Computer and Information Sciences",
  "Architectural Engineering.": "Engineering",
  "Parks, Recreation and Leisure Facilities Management.":
    "Public Administration and Human Services",
  "Natural Resources and Conservation, Other.":
    "Agriculture and Natural Resources",
  "Arts, Entertainment,and Media Management.": "Visual Arts and Music",
  "Bilingual, Multilingual, and Multicultural Education.": "Education",
  "Zoology/Animal Biology.": "Life Sciences and Biology",
  "Business Operations Support and Assistant Services.":
    "Business and Management",
  "Biological and Physical Sciences.": "Miscellaneous",
  "Drafting/Design Engineering Technologies/Technicians.": "Engineering",
  "Family and Consumer Economics and Related Studies.": "Miscellaneous",
  "Natural Resources Management and Policy.":
    "Agriculture and Natural Resources",
  "Neurobiology and Neurosciences.": "Life Sciences and Biology",
  "Ceramic Sciences and Engineering.": "Engineering",
  "Science, Technology and Society.": "Miscellaneous",
  "Computer Systems Analysis.": "Computer and Information Sciences",
  "American Sign Language.": "Miscellaneous",
  "Agriculture, Agriculture Operations, and Related Sciences, Other.":
    "Agriculture and Natural Resources",
  "Middle/Near Eastern and Semitic Languages, Literatures, and Linguistics.":
    "Miscellaneous",
  "Communications Technologies/Technicians and Support Services, Other.":
    "Communications and Journalism",
  "English Language and Literature/Letters, Other.":
    "English, Liberal Arts, and Humanities",
  "Construction Engineering.": "Engineering",
  "Human Biology.": "Miscellaneous",
  "Agricultural and Food Products Processing.":
    "Agriculture and Natural Resources",
  "Agricultural Mechanization.": "Agriculture and Natural Resources",
  "Nuclear Engineering.": "Engineering",
  "Building/Construction Finishing, Management, and Inspection.":
    "Transportation, Construction, and Architecture",
  "Genetics.": "Life Sciences and Biology",
  "Slavic, Baltic and Albanian Languages, Literatures, and Linguistics.":
    "Miscellaneous",
  "Architecture and Related Services, Other.":
    "Transportation, Construction, and Architecture",
  "Agricultural Production Operations.": "Agriculture and Natural Resources",
  "Cosmetology and Related Personal Grooming Services.": "Miscellaneous",
  "Astronomy and Astrophysics.": "Physical Sciences",
  "Legal Professions and Studies, Other.": "Miscellaneous",
  "Alternative and Complementary Medicine and Medical Systems.":
    "Health and Nursing",
  "Polymer/Plastics Engineering.": "Engineering",
  "Civil Engineering Technologies/Technicians.": "Engineering",
  "Agriculture/Veterinary Preparatory Programs.":
    "Agriculture and Natural Resources",
  "Fishing and Fisheries Sciences and Management.":
    "Agriculture and Natural Resources",
  "Sustainability Studies.": "Miscellaneous",
  "Agricultural and Domestic Animal Services.":
    "Agriculture and Natural Resources",
  "Natural Sciences.": "Miscellaneous",
  "Architectural History and Criticism.":
    "Transportation, Construction, and Architecture",
  "Environmental Design.": "Transportation, Construction, and Architecture",
  "Archeology.": "Social Sciences excluding Economics",
  "Electromechanical Instrumentation and Maintenance Technologies/Technicians.":
    "Engineering",
  "Visual and Performing Arts, Other.": "Visual Arts and Music",
  "Social and Philosophical Foundations of Education.": "Education",
  "Mathematics and Statistics, Other.": "Mathematics and Statistics",
  "International and Comparative Education.": "Education",
  "Educational/Instructional Media Design.": "Education",
  "Family and Consumer Sciences/Human Sciences, Other.": "Miscellaneous",
  "Allied Health and Medical Assisting Services.": "Health and Nursing",
  "Movement and Mind-Body Therapies and Education.": "Health and Nursing",
  "Marine Sciences.": "Miscellaneous",
  "Biomathematics, Bioinformatics, and Computational Biology.":
    "Life Sciences and Biology",
  "Legal Research and Advanced Professional Studies.": "Miscellaneous",
};
// const exhaustivePrograms = [
//   "Accounting and Related Services.",
//   "Aerospace, Aeronautical and Astronautical Engineering.",
//   "Agricultural Business and Management.",
//   "Agricultural Engineering.",
//   "Agricultural Mechanization.",
//   "Agricultural Production Operations.",
//   "Agricultural Public Services.",
//   "Agricultural and Domestic Animal Services.",
//   "Agricultural and Food Products Processing.",
//   "Agriculture, Agriculture Operations, and Related Sciences, Other.",
//   "Agriculture, General.",
//   "Agriculture/Veterinary Preparatory Programs.",
//   "Air Transportation.",
//   "Allied Health Diagnostic, Intervention, and Treatment Professions.",
//   "Allied Health and Medical Assisting Services.",
//   "Alternative and Complementary Medical Support Services.",
//   "Alternative and Complementary Medicine and Medical Systems.",
//   "American Sign Language.",
//   "Animal Sciences.",
//   "Anthropology.",
//   "Apparel and Textiles.",
//   "Applied Horticulture and Horticultural Business Services.",
//   "Applied Mathematics.",
//   "Archeology.",
//   "Architectural Engineering Technologies/Technicians.",
//   "Architectural Engineering.",
//   "Architectural History and Criticism.",
//   "Architectural Sciences and Technology.",
//   "Architecture and Related Services, Other.",
//   "Architecture.",
//   "Area Studies.",
//   "Arts, Entertainment,and Media Management.",
//   "Astronomy and Astrophysics.",
//   "Atmospheric Sciences and Meteorology.",
//   "Audiovisual Communications Technologies/Technicians.",
//   "Behavioral Sciences.",
//   "Bible/Biblical Studies.",
//   "Bilingual, Multilingual, and Multicultural Education.",
//   "Biochemical Engineering.",
//   "Biochemistry, Biophysics and Molecular Biology.",
//   "Bioethics/Medical Ethics.",
//   "Biological and Biomedical Sciences, Other.",
//   "Biological and Physical Sciences.",
//   "Biological/Biosystems Engineering.",
//   "Biology Technician/Biotechnology Laboratory Technician.",
//   "Biology, General.",
//   "Biomathematics, Bioinformatics, and Computational Biology.",
//   "Biomedical/Medical Engineering.",
//   "Biopsychology.",
//   "Biotechnology.",
//   "Building/Construction Finishing, Management, and Inspection.",
//   "Business Administration, Management and Operations.",
//   "Business Operations Support and Assistant Services.",
//   "Business, Management, Marketing, and Related Support Services, Other.",
//   "Business/Commerce, General.",
//   "Business/Corporate Communications.",
//   "Business/Managerial Economics.",
//   "Cell/Cellular Biology and Anatomical Sciences.",
//   "Ceramic Sciences and Engineering.",
//   "Chemical Engineering.",
//   "Chemistry.",
//   "City/Urban, Community and Regional Planning.",
//   "Civil Engineering Technologies/Technicians.",
//   "Civil Engineering.",
//   "Classical and Ancient Studies.",
//   "Classics and Classical Languages, Literatures, and Linguistics.",
//   "Clinical, Counseling and Applied Psychology.",
//   "Clinical/Medical Laboratory Science/Research and Allied Professions.",
//   "Cognitive Science.",
//   "Communication Disorders Sciences and Services.",
//   "Communication and Media Studies.",
//   "Communication, Journalism, and Related Programs, Other.",
//   "Communications Technologies/Technicians and Support Services, Other.",
//   "Communications Technology/Technician.",
//   "Community Organization and Advocacy.",
//   "Computational Science.",
//   "Computer Engineering Technologies/Technicians.",
//   "Computer Engineering.",
//   "Computer Programming.",
//   "Computer Science.",
//   "Computer Software and Media Applications.",
//   "Computer Systems Analysis.",
//   "Computer Systems Networking and Telecommunications.",
//   "Computer and Information Sciences and Support Services, Other.",
//   "Computer and Information Sciences, General.",
//   "Computer/Information Technology Administration and Management.",
//   "Construction Engineering Technologies.",
//   "Construction Engineering.",
//   "Construction Management.",
//   "Construction Trades, Other.",
//   "Cosmetology and Related Personal Grooming Services.",
//   "Crafts/Craft Design, Folk Art and Artisanry.",
//   "Criminal Justice and Corrections.",
//   "Criminology.",
//   "Culinary Arts and Related Services.",
//   "Cultural Studies/Critical Theory and Analysis.",
//   "Curriculum and Instruction.",
//   "Dance.",
//   "Data Processing.",
//   "Dental Support Services and Allied Professions.",
//   "Design and Applied Arts.",
//   "Dietetics and Clinical Nutrition Services.",
//   "Drafting/Design Engineering Technologies/Technicians.",
//   "Drama/Theatre Arts and Stagecraft.",
//   "East Asian Languages, Literatures, and Linguistics.",
//   "Ecology, Evolution, Systematics, and Population Biology.",
//   "Economics.",
//   "Education, General.",
//   "Education, Other.",
//   "Educational Assessment, Evaluation, and Research.",
//   "Educational/Instructional Media Design.",
//   "Electrical Engineering Technologies/Technicians.",
//   "Electrical, Electronics and Communications Engineering.",
//   "Electromechanical Engineering.",
//   "Electromechanical Instrumentation and Maintenance Technologies/Technicians.",
//   "Energy Systems Technologies/Technicians.",
//   "Energy and Biologically Based Therapies.",
//   "Engineering Mechanics.",
//   "Engineering Physics.",
//   "Engineering Science.",
//   "Engineering Technologies/Technicians, Other.",
//   "Engineering Technology, General.",
//   "Engineering, General.",
//   "Engineering, Other.",
//   "Engineering-Related Fields.",
//   "Engineering-Related Technologies.",
//   "English Language and Literature, General.",
//   "English Language and Literature/Letters, Other.",
//   "Entrepreneurial and Small Business Operations.",
//   "Environmental Control Technologies/Technicians.",
//   "Environmental Design.",
//   "Environmental/Environmental Health Engineering.",
//   "Ethnic, Cultural Minority, Gender, and Group Studies.",
//   "Family and Consumer Economics and Related Studies.",
//   "Family and Consumer Sciences/Human Sciences Business Services.",
//   "Family and Consumer Sciences/Human Sciences, General.",
//   "Family and Consumer Sciences/Human Sciences, Other.",
//   "Film/Video and Photographic Arts.",
//   "Finance and Financial Management Services.",
//   "Fine and Studio Arts.",
//   "Fire Protection.",
//   "Fishing and Fisheries Sciences and Management.",
//   "Food Science and Technology.",
//   "Foods, Nutrition, and Related Services.",
//   "Foreign Languages, Literatures, and Linguistics, Other.",
//   "Forest Engineering.",
//   "Forestry.",
//   "Funeral Service and Mortuary Science.",
//   "General Sales, Merchandising and Related Marketing Operations.",
//   "Genetics.",
//   "Geography and Cartography.",
//   "Geological and Earth Sciences/Geosciences.",
//   "Germanic Languages, Literatures, and Linguistics.",
//   "Gerontology.",
//   "Graphic Communications.",
//   "Health Professions and Related Clinical Sciences, Other.",
//   "Health Services/Allied Health/Health Sciences, General.",
//   "Health and Medical Administrative Services.",
//   "Health and Physical Education/Fitness.",
//   "Health-Related Knowledge and Skills.",
//   "Health/Medical Preparatory Programs.",
//   "Heavy/Industrial Equipment Maintenance Technologies.",
//   "Historic Preservation and Conservation.",
//   "History.",
//   "Holocaust and Related Studies.",
//   "Homeland Security, Law Enforcement, Firefighting and Related Protective Services, Other.",
//   "Homeland Security.",
//   "Hospitality Administration/Management.",
//   "Housing and Human Environments.",
//   "Human Biology.",
//   "Human Computer Interaction.",
//   "Human Development, Family Studies, and Related Services.",
//   "Human Resources Management and Services.",
//   "Human Services, General.",
//   "Industrial Engineering.",
//   "Industrial Production Technologies/Technicians.",
//   "Information Science/Studies.",
//   "Insurance.",
//   "Intercultural/Multicultural and Diversity Studies.",
//   "Interior Architecture.",
//   "International Agriculture.",
//   "International Business.",
//   "International Relations and National Security Studies.",
//   "International and Comparative Education.",
//   "International/Global Studies.",
//   "Journalism.",
//   "Landscape Architecture.",
//   "Legal Professions and Studies, Other.",
//   "Legal Research and Advanced Professional Studies.",
//   "Legal Support Services.",
//   "Leisure and Recreational Activities.",
//   "Liberal Arts and Sciences, General Studies and Humanities.",
//   "Linguistic, Comparative, and Related Language Studies and Services.",
//   "Literature.",
//   "Management Information Systems and Services.",
//   "Management Sciences and Quantitative Methods.",
//   "Manufacturing Engineering.",
//   "Marine Sciences.",
//   "Marine Transportation.",
//   "Marketing.",
//   "Materials Engineering",
//   "Materials Sciences.",
//   "Mathematics and Statistics, Other.",
//   "Mathematics.",
//   "Mechanical Engineering Related Technologies/Technicians.",
//   "Mechanical Engineering.",
//   "Mechatronics, Robotics, and Automation Engineering.",
//   "Medical Illustration and Informatics.",
//   "Mental and Social Health Services and Allied Professions.",
//   "Microbiological Sciences and Immunology.",
//   "Middle/Near Eastern and Semitic Languages, Literatures, and Linguistics.",
//   "Mining and Petroleum Technologies/Technicians.",
//   "Missions/Missionary Studies and Missiology.",
//   "Movement and Mind-Body Therapies and Education.",
//   "Multi-/Interdisciplinary Studies, General.",
//   "Multi/Interdisciplinary Studies, Other.",
//   "Music.",
//   "Natural Resources Conservation and Research.",
//   "Natural Resources Management and Policy.",
//   "Natural Resources and Conservation, Other.",
//   "Natural Sciences.",
//   "Naval Architecture and Marine Engineering.",
//   "Neurobiology and Neurosciences.",
//   "Non-Professional General Legal Studies (Undergraduate).",
//   "Nuclear Engineering Technologies/Technicians.",
//   "Nuclear Engineering.",
//   "Nursing.",
//   "Nutrition Sciences.",
//   "Ocean Engineering.",
//   "Operations Research.",
//   "Outdoor Education.",
//   "Parks, Recreation and Leisure Facilities Management.",
//   "Parks, Recreation and Leisure Studies.",
//   "Parks, Recreation, Leisure, and Fitness Studies, Other.",
//   "Pastoral Counseling and Specialized Ministries.",
//   "Peace Studies and Conflict Resolution.",
//   "Petroleum Engineering.",
//   "Pharmacy, Pharmaceutical Sciences, and Administration.",
//   "Philosophy and Religious Studies, General.",
//   "Philosophy and Religious Studies, Other.",
//   "Philosophy.",
//   "Physics.",
//   "Physiology, Pathology and Related Sciences.",
//   "Plant Sciences.",
//   "Political Science and Government.",
//   "Polymer/Plastics Engineering.",
//   "Practical Nursing, Vocational Nursing and Nursing Assistants.",
//   "Program name",
//   "Psychology, General.",
//   "Psychology, Other.",
//   "Public Administration and Social Service Professions, Other.",
//   "Public Administration.",
//   "Public Health.",
//   "Public Policy Analysis.",
//   "Public Relations, Advertising, and Applied Communication.",
//   "Quality Control and Safety Technologies/Technicians.",
//   "Radio, Television, and Digital Communication.",
//   "Real Estate.",
//   "Registered Nursing, Nursing Administration, Nursing Research and Clinical Nursing.",
//   "Rehabilitation and Therapeutic Professions.",
//   "Religion/Religious Studies.",
//   "Religious Education.",
//   "Religious/Sacred Music.",
//   "Research and Experimental Psychology.",
//   "Rhetoric and Composition/Writing Studies.",
//   "Romance Languages, Literatures, and Linguistics.",
//   "Science Technologies/Technicians, General.",
//   "Science Technologies/Technicians, Other.",
//   "Science, Technology and Society.",
//   "Security Science and Technology.",
//   "Slavic, Baltic and Albanian Languages, Literatures, and Linguistics.",
//   "Social Sciences, General.",
//   "Social Sciences, Other.",
//   "Social Work.",
//   "Social and Philosophical Foundations of Education.",
//   "Sociology and Anthropology.",
//   "Sociology.",
//   "Somatic Bodywork and Related Therapeutic Services.",
//   "Special Education and Teaching.",
//   "Specialized Sales, Merchandising and  Marketing Operations.",
//   "Statistics.",
//   "Surveying Engineering.",
//   "Sustainability Studies.",
//   "Systems Engineering.",
//   "Systems Science and Theory.",
//   "Teacher Education and Professional Development, Specific Levels and Methods.",
//   "Teacher Education and Professional Development, Specific Subject Areas.",
//   "Teaching English or French as a Second or Foreign Language.",
//   "Textile Sciences and Engineering.",
//   "Theological and Ministerial Studies.",
//   "Theology and Religious Vocations, Other.",
//   "Transportation and Materials Moving, Other.",
//   "Urban Studies/Affairs.",
//   "Vehicle Maintenance and Repair Technologies.",
//   "Veterinary Biomedical and Clinical Sciences.",
//   "Veterinary Medicine.",
//   "Veterinary/Animal Health Technologies/Technicians.",
//   "Visual and Performing Arts, General.",
//   "Visual and Performing Arts, Other.",
//   "Wildlife and Wildlands Science and Management.",
//   "Zoology/Animal Biology.",
// ];

export default programs;
