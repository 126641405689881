export const programTypeToPrograms: Record<string, string[]> = {
  Miscellaneous: [
    "Multi-/Interdisciplinary Studies, General.",
    "Multi/Interdisciplinary Studies, Other.",
    "Non-Professional General Legal Studies (Undergraduate).",
    "Romance Languages, Literatures, and Linguistics.",
    "Linguistic, Comparative, and Related Language Studies and Services.",
    "Nutrition Sciences.",
    "Human Development, Family Studies, and Related Services.",
    "Family and Consumer Sciences/Human Sciences, General.",
    "Behavioral Sciences.",
    "Classical and Ancient Studies.",
    "Intercultural/Multicultural and Diversity Studies.",
    "Apparel and Textiles.",
    "Housing and Human Environments.",
    "East Asian Languages, Literatures, and Linguistics.",
    "Culinary Arts and Related Services.",
    "Foods, Nutrition, and Related Services.",
    "Legal Support Services.",
    "Classics and Classical Languages, Literatures, and Linguistics.",
    "Gerontology.",
    "Cognitive Science.",
    "International/Global Studies.",
    "Germanic Languages, Literatures, and Linguistics.",
    "Cultural Studies/Critical Theory and Analysis.",
    "Peace Studies and Conflict Resolution.",
    "Biological and Physical Sciences.",
    "Family and Consumer Economics and Related Studies.",
    "Science, Technology and Society.",
    "American Sign Language.",
    "Middle/Near Eastern and Semitic Languages, Literatures, and Linguistics.",
    "Human Biology.",
    "Slavic, Baltic and Albanian Languages, Literatures, and Linguistics.",
    "Cosmetology and Related Personal Grooming Services.",
    "Legal Professions and Studies, Other.",
    "Sustainability Studies.",
    "Natural Sciences.",
    "Family and Consumer Sciences/Human Sciences, Other.",
    "Marine Sciences.",
    "Legal Research and Advanced Professional Studies.",
  ],
  "English, Liberal Arts, and Humanities": [
    "English Language and Literature, General.",
    "History.",
    "Liberal Arts and Sciences, General Studies and Humanities.",
    "Rhetoric and Composition/Writing Studies.",
    "Literature.",
    "English Language and Literature/Letters, Other.",
  ],
  "Visual Arts and Music": [
    "Fine and Studio Arts.",
    "Dance.",
    "Drama/Theatre Arts and Stagecraft.",
    "Film/Video and Photographic Arts.",
    "Design and Applied Arts.",
    "Music.",
    "Visual and Performing Arts, General.",
    "Arts, Entertainment,and Media Management.",
    "Visual and Performing Arts, Other.",
  ],
  "Public Administration and Human Services": [
    "Social Work.",
    "Health and Physical Education/Fitness.",
    "Security Science and Technology.",
    "Criminal Justice and Corrections.",
    "Public Policy Analysis.",
    "Parks, Recreation and Leisure Studies.",
    "Human Services, General.",
    "Parks, Recreation, Leisure, and Fitness Studies, Other.",
    "Public Administration and Social Service Professions, Other.",
    "Public Administration.",
    "Homeland Security.",
    "Fire Protection.",
    "Community Organization and Advocacy.",
    "Parks, Recreation and Leisure Facilities Management.",
  ],
  Education: [
    "Teacher Education and Professional Development, Specific Levels and Methods.",
    "Teacher Education and Professional Development, Specific Subject Areas.",
    "Special Education and Teaching.",
    "Educational Assessment, Evaluation, and Research.",
    "Teaching English or French as a Second or Foreign Language.",
    "Education, Other.",
    "Education, General.",
    "Curriculum and Instruction.",
    "Bilingual, Multilingual, and Multicultural Education.",
    "Social and Philosophical Foundations of Education.",
    "International and Comparative Education.",
    "Educational/Instructional Media Design.",
  ],
  "Mathematics and Statistics": [
    "Mathematics.",
    "Applied Mathematics.",
    "Statistics.",
    "Mathematics and Statistics, Other.",
  ],
  "Social Sciences excluding Economics": [
    "Political Science and Government.",
    "Sociology.",
    "Anthropology.",
    "Geography and Cartography.",
    "Ethnic, Cultural Minority, Gender, and Group Studies.",
    "Area Studies.",
    "Urban Studies/Affairs.",
    "Criminology.",
    "Social Sciences, General.",
    "International Relations and National Security Studies.",
    "Social Sciences, Other.",
    "Archeology.",
  ],
  "Communications and Journalism": [
    "Communication and Media Studies.",
    "Journalism.",
    "Public Relations, Advertising, and Applied Communication.",
    "Radio, Television, and Digital Communication.",
    "Communication, Journalism, and Related Programs, Other.",
    "Graphic Communications.",
    "Audiovisual Communications Technologies/Technicians.",
    "Communications Technologies/Technicians and Support Services, Other.",
  ],
  "Business and Management": [
    "Business Administration, Management and Operations.",
    "Management Sciences and Quantitative Methods.",
    "Accounting and Related Services.",
    "Management Information Systems and Services.",
    "Real Estate.",
    "Marketing.",
    "Human Resources Management and Services.",
    "Business/Managerial Economics.",
    "Business/Commerce, General.",
    "International Business.",
    "Finance and Financial Management Services.",
    "Entrepreneurial and Small Business Operations.",
    "Specialized Sales, Merchandising and  Marketing Operations.",
    "Business/Corporate Communications.",
    "Construction Management.",
    "General Sales, Merchandising and Related Marketing Operations.",
    "Insurance.",
    "Hospitality Administration/Management.",
    "Business, Management, Marketing, and Related Support Services, Other.",
    "Business Operations Support and Assistant Services.",
  ],
  Psychology: [
    "Psychology, General.",
    "Psychology, Other.",
    "Research and Experimental Psychology.",
    "Clinical, Counseling and Applied Psychology.",
  ],
  "Health and Nursing": [
    "Registered Nursing, Nursing Administration, Nursing Research and Clinical Nursing.",
    "Health and Medical Administrative Services.",
    "Clinical/Medical Laboratory Science/Research and Allied Professions.",
    "Public Health.",
    "Allied Health Diagnostic, Intervention, and Treatment Professions.",
    "Health Services/Allied Health/Health Sciences, General.",
    "Communication Disorders Sciences and Services.",
    "Dietetics and Clinical Nutrition Services.",
    "Health Professions and Related Clinical Sciences, Other.",
    "Health/Medical Preparatory Programs.",
    "Dental Support Services and Allied Professions.",
    "Somatic Bodywork and Related Therapeutic Services.",
    "Mental and Social Health Services and Allied Professions.",
    "Rehabilitation and Therapeutic Professions.",
    "Pharmacy, Pharmaceutical Sciences, and Administration.",
    "Alternative and Complementary Medicine and Medical Systems.",
    "Allied Health and Medical Assisting Services.",
    "Movement and Mind-Body Therapies and Education.",
  ],
  "Physical Sciences": [
    "Chemistry.",
    "Atmospheric Sciences and Meteorology.",
    "Geological and Earth Sciences/Geosciences.",
    "Physics.",
    "Materials Sciences.",
    "Science Technologies/Technicians, Other.",
    "Astronomy and Astrophysics.",
  ],
  "Computer and Information Sciences": [
    "Computer and Information Sciences, General.",
    "Computer Science.",
    "Computer and Information Sciences and Support Services, Other.",
    "Information Science/Studies.",
    "Computer Programming.",
    "Data Processing.",
    "Computer/Information Technology Administration and Management.",
    "Computer Systems Networking and Telecommunications.",
    "Computer Software and Media Applications.",
    "Computer Systems Analysis.",
  ],
  "Philosophy and Religious Studies": [
    "Philosophy.",
    "Religion/Religious Studies.",
    "Religious Education.",
    "Bible/Biblical Studies.",
    "Pastoral Counseling and Specialized Ministries.",
    "Theological and Ministerial Studies.",
    "Missions/Missionary Studies and Missiology.",
    "Theology and Religious Vocations, Other.",
    "Religious/Sacred Music.",
  ],
  "Life Sciences and Biology": [
    "Physiology, Pathology and Related Sciences.",
    "Biology, General.",
    "Ecology, Evolution, Systematics, and Population Biology.",
    "Biochemistry, Biophysics and Molecular Biology.",
    "Cell/Cellular Biology and Anatomical Sciences.",
    "Microbiological Sciences and Immunology.",
    "Biological and Biomedical Sciences, Other.",
    "Biotechnology.",
    "Zoology/Animal Biology.",
    "Neurobiology and Neurosciences.",
    "Genetics.",
    "Biomathematics, Bioinformatics, and Computational Biology.",
  ],
  Engineering: [
    "Mechanical Engineering.",
    "Environmental/Environmental Health Engineering.",
    "Electrical, Electronics and Communications Engineering.",
    "Industrial Production Technologies/Technicians.",
    "Engineering Science.",
    "Agricultural Engineering.",
    "Computer Engineering.",
    "Civil Engineering.",
    "Industrial Engineering.",
    "Engineering, General.",
    "Engineering, Other.",
    "Construction Engineering Technologies.",
    "Engineering Technologies/Technicians, Other.",
    "Biomedical/Medical Engineering.",
    "Quality Control and Safety Technologies/Technicians.",
    "Operations Research.",
    "Mechanical Engineering Related Technologies/Technicians.",
    "Aerospace, Aeronautical and Astronautical Engineering.",
    "Chemical Engineering.",
    "Engineering-Related Fields.",
    "Electrical Engineering Technologies/Technicians.",
    "Biological/Biosystems Engineering.",
    "Engineering Technology, General.",
    "Petroleum Engineering.",
    "Energy Systems Technologies/Technicians.",
    "Systems Engineering.",
    "Engineering-Related Technologies.",
    "Materials Engineering",
    "Engineering Physics.",
    "Manufacturing Engineering.",
    "Architectural Engineering.",
    "Drafting/Design Engineering Technologies/Technicians.",
    "Ceramic Sciences and Engineering.",
    "Construction Engineering.",
    "Nuclear Engineering.",
    "Polymer/Plastics Engineering.",
    "Civil Engineering Technologies/Technicians.",
    "Electromechanical Instrumentation and Maintenance Technologies/Technicians.",
  ],
  "Agriculture and Natural Resources": [
    "Agricultural Business and Management.",
    "Wildlife and Wildlands Science and Management.",
    "Forestry.",
    "Plant Sciences.",
    "Natural Resources Conservation and Research.",
    "Animal Sciences.",
    "Agriculture, General.",
    "Food Science and Technology.",
    "Agricultural Public Services.",
    "Applied Horticulture and Horticultural Business Services.",
    "International Agriculture.",
    "Natural Resources and Conservation, Other.",
    "Natural Resources Management and Policy.",
    "Agriculture, Agriculture Operations, and Related Sciences, Other.",
    "Agricultural and Food Products Processing.",
    "Agricultural Mechanization.",
    "Agricultural Production Operations.",
    "Agriculture/Veterinary Preparatory Programs.",
    "Fishing and Fisheries Sciences and Management.",
    "Agricultural and Domestic Animal Services.",
  ],
  "Transportation, Construction, and Architecture": [
    "Architecture.",
    "Architectural Sciences and Technology.",
    "Landscape Architecture.",
    "Air Transportation.",
    "City/Urban, Community and Regional Planning.",
    "Interior Architecture.",
    "Marine Transportation.",
    "Building/Construction Finishing, Management, and Inspection.",
    "Architecture and Related Services, Other.",
    "Architectural History and Criticism.",
    "Environmental Design.",
  ],
  Economics: ["Economics."],
};

export default programTypeToPrograms;
