export const originalColumnToType = {
    "Unit ID": "unitId",
    "Institution name": "institutionName",
    "State FIPS code": 'stateFIPSCode',
    "State": "state",
    "Program CIP code": 'programCIPCode',
    "Program name": "programName",
    "Program category": "programCategory",
    "Control": "control",
    "Carnegie classification (2015)": "carnegieClassification2015",
    "Admissions rate": 'admissionsRate',
    "Credential level": 'credentialLevel',
    "Credential level description": "credentialLevelDescription",
    "College Scorecard cohort count": 'collegeScorecardCohortCount',
    "Annual net tuition cost": 'annualNetTuitionCost',
    "Four-year net tuition cost": 'fourYearNetTuitionCost',
    "Annual education-related spending": 'annualEducationRelatedSpending',
    "Four-year education-related spending": 'fourYearEducationRelatedSpending',
    "Share of students who graduate in 4 years": 'shareOfStudentWhoGraduateIn4Years',
    "Share of students who graduate in 5 years": 'shareOfStudentWhoGraduateIn5Years',
    "Share of students who graduate in 6 years": 'shareOfStudentWhoGraduateIn6Years',
    "Share of students who transfer out within 6 years": 'shareOfStudentWhoTransferOutIn6Years',
    "Share of students still enrolled after 6 years": 'shareOfStudentWhoRemainIn6Years',
    "Share of students who are no longer enrolled": 'shareOfStudentWhoNoLongerEnrolledIn6Years',
    "Absolute increase in lifetime earnings": 'absoluteIncreaseInLifetimeEarnings',
    "Percentage increase in lifetime earnings": "percentageIncreaseInLifetimeEarnings",
    "Lifetime return on investment (ROI)": 'lifetimeReturnOnInvestmentROI',
    "Age at which ROI turns positive": 'ageAtWhichROITurnsPositive',
    "Rank of program by ROI": 'rankOfProgramByROI',
    "ROI if student takes 5 years to graduate": 'ROIIfStudentTakes5YearsToGraduate',
    "ROI if student takes 6 years to graduate": 'ROIIfStudentTakes6YearsToGraduate',
    "ROI if student drops out before finishing degree": 'ROIIfStudentDropsBeforeGraduating',
    "ROI weighted by institution's completion outcomes": 'ROIWeightedByInstitutionsCompletionOutcomes',
    "ROI based on institution's education-related spending": 'ROIBasedOnInstitutionsEducationRelatedSpending',
    "ROI if student takes 5 years to graduate, based on spending": 'ROIBasedOnInstitutionsEducationRelatedSpending5years',
    "ROI if student takes 6 years to graduate, based on spending": 'ROIBasedOnInstitutionsEducationRelatedSpending6Years',
    "ROI if student drops out before finishing degree, based on spending": 'ROIBasedOnInstitutionsEducationRelatedSpendingDrops',
    "ROI weighted by institution's completion outcomes, based on spending": 'ROIBasedOnInstitutionsEducationRelatedSpendingCompletionOutcomes',
    "Estimated earnings, ages 23-25": 'estimatedEarnings23',
    "Estimated earnings, ages 26-28": 'estimatedEarnings26',
    "Estimated earnings, ages 29-31": 'estimatedEarnings29',
    "Estimated earnings, ages 32-34": 'estimatedEarnings32',
    "Estimated earnings, ages 35-37": 'estimatedEarnings35',
    "Estimated earnings, ages 38-40": 'estimatedEarnings38',
    "Estimated earnings, ages 41-43": 'estimatedEarnings41',
    "Estimated earnings, ages 44-46": 'estimatedEarnings44',
    "Estimated earnings, ages 47-49": 'estimatedEarnings47',
    "Estimated earnings, ages 50-52": 'estimatedEarnings50',
    "Estimated earnings, ages 53-55": 'estimatedEarnings53',
    "Estimated earnings, ages 56-58": 'estimatedEarnings56',
    "Estimated earnings, ages 59-61": 'estimatedEarnings59',
    "Estimated earnings, ages 62-64": 'estimatedEarnings62',
    "Estimated counterfactual earnings, ages 19-20": 'estimatedCounterfactualEarnings19',
    "Estimated counterfactual earnings, ages 21-22": 'estimatedCounterfactualEarnings21',
    "Estimated counterfactual earnings, ages 23-24": 'estimatedCounterfactualEarnings23',
    "Estimated counterfactual earnings, ages 26-28": 'estimatedCounterfactualEarnings26',
    "Estimated counterfactual earnings, ages 29-31": 'estimatedCounterfactualEarnings29',
    "Estimated counterfactual earnings, ages 32-34": 'estimatedCounterfactualEarnings32',
    "Estimated counterfactual earnings, ages 35-37": 'estimatedCounterfactualEarnings35',
    "Estimated counterfactual earnings, ages 38-40": 'estimatedCounterfactualEarnings38',
    "Estimated counterfactual earnings, ages 41-43": 'estimatedCounterfactualEarnings41',
    "Estimated counterfactual earnings, ages 44-46": 'estimatedCounterfactualEarnings44',
    "Estimated counterfactual earnings, ages 47-49": 'estimatedCounterfactualEarnings47',
    "Estimated counterfactual earnings, ages 50-52": 'estimatedCounterfactualEarnings50',
    "Estimated counterfactual earnings, ages 53-55": 'estimatedCounterfactualEarnings53',
    "Estimated counterfactual earnings, ages 56-58": 'estimatedCounterfactualEarnings56',
    "Estimated counterfactual earnings, ages 59-61": 'estimatedCounterfactualEarnings59',
    "Estimated counterfactual earnings, ages 62-64": 'estimatedCounterfactualEarnings62',
    "Results available for all specifications": 'resultsAvailableForAllSpecifications'
  };

export const typeToOriginalColumn = {
    "unitId": "Unit ID",
    "institutionName": "Institution name",
    "stateFIPSCode": "State FIPS code",
    "state": "State",
    "programCIPCode": "Program CIP code",
    "programName": "Program name",
    "programCategory": "Program category",
    "control": "Control",
    "carnegieClassification2015": "Carnegie classification (2015)",
    "admissionsRate": "Admissions rate",
    "credentialLevel": "Credential level",
    "credentialLevelDescription": "Credential level description",
    "collegeScorecardCohortCount": "College Scorecard cohort count",
    "annualNetTuitionCost": "Annual net tuition cost",
    "fourYearNetTuitionCost": "Four-year net tuition cost",
    "annualEducationRelatedSpending": "Annual education-related spending",
    "fourYearEducationRelatedSpending": "Four-year education-related spending",
    "shareOfStudentWhoGraduateIn4Years": "Share of students who graduate in 4 years",
    "shareOfStudentWhoGraduateIn5Years": "Share of students who graduate in 5 years",
    "shareOfStudentWhoGraduateIn6Years": "Share of students who graduate in 6 years",
    "shareOfStudentWhoTransferOutIn6Years": "Share of students who transfer out within 6 years",
    "shareOfStudentWhoRemainIn6Years": "Share of students still enrolled after 6 years",
    "shareOfStudentWhoNoLongerEnrolledIn6Years": "Share of students who are no longer enrolled",
    "absoluteIncreaseInLifetimeEarnings": "Absolute increase in lifetime earnings",
    "percentageIncreaseInLifetimeEarnings": "Percentage increase in lifetime earnings",
    "lifetimeReturnOnInvestmentROI": "Lifetime return on investment (ROI)",
    "ageAtWhichROITurnsPositive": "Age at which ROI turns positive",
    "rankOfProgramByROI": "Rank of program by ROI",
    "ROIIfStudentTakes5YearsToGraduate": "ROI if student takes 5 years to graduate",
    "ROIIfStudentTakes6YearsToGraduate": "ROI if student takes 6 years to graduate",
    "ROIIfStudentDropsBeforeGraduating": "ROI if student drops out before finishing degree",
    "ROIWeightedByInstitutionsCompletionOutcomes": "ROI weighted by institution's completion outcomes",
    "ROIBasedOnInstitutionsEducationRelatedSpending": "ROI based on institution's education-related spending",
    "ROIBasedOnInstitutionsEducationRelatedSpending5years": "ROI if student takes 5 years to graduate, based on spending",
    "ROIBasedOnInstitutionsEducationRelatedSpending6Years": "ROI if student takes 6 years to graduate, based on spending",
    "ROIBasedOnInstitutionsEducationRelatedSpendingDrops": "ROI if student drops out before finishing degree, based on spending",
    "ROIBasedOnInstitutionsEducationRelatedSpendingCompletionOutcomes": "ROI weighted by institution's completion outcomes, based on spending",
    "estimatedEarnings23": "Estimated earnings, ages 23-25",
    "estimatedEarnings26": "Estimated earnings, ages 26-28",
    "estimatedEarnings29": "Estimated earnings, ages 29-31",
    "estimatedEarnings32": "Estimated earnings, ages 32-34",
    "estimatedEarnings35": "Estimated earnings, ages 35-37",
    "estimatedEarnings38": "Estimated earnings, ages 38-40",
    "estimatedEarnings41": "Estimated earnings, ages 41-43",
    "estimatedEarnings44": "Estimated earnings, ages 44-46",
    "estimatedEarnings47": "Estimated earnings, ages 47-49",
    "estimatedEarnings50": "Estimated earnings, ages 50-52",
    "estimatedEarnings53": "Estimated earnings, ages 53-55",
    "estimatedEarnings56": "Estimated earnings, ages 56-58",
    "estimatedEarnings59": "Estimated earnings, ages 59-61",
    "estimatedEarnings62": "Estimated earnings, ages 62-64",
    "estimatedCounterfactualEarnings19": "Estimated counterfactual earnings, ages 19-20",
    "estimatedCounterfactualEarnings21": "Estimated counterfactual earnings, ages 21-22",
    "estimatedCounterfactualEarnings23": "Estimated counterfactual earnings, ages 23-24",
    "estimatedCounterfactualEarnings26": "Estimated counterfactual earnings, ages 26-28",
    "estimatedCounterfactualEarnings29": "Estimated counterfactual earnings, ages 29-31",
    "estimatedCounterfactualEarnings32": "Estimated counterfactual earnings, ages 32-34",
    "estimatedCounterfactualEarnings35": "Estimated counterfactual earnings, ages 35-37",
    "estimatedCounterfactualEarnings38": "Estimated counterfactual earnings, ages 38-40",
    "estimatedCounterfactualEarnings41": "Estimated counterfactual earnings, ages 41-43",
    "estimatedCounterfactualEarnings44": "Estimated counterfactual earnings, ages 44-46",
    "estimatedCounterfactualEarnings47": "Estimated counterfactual earnings, ages 47-49",
    "estimatedCounterfactualEarnings50": "Estimated counterfactual earnings, ages 50-52",
    "estimatedCounterfactualEarnings53": "Estimated counterfactual earnings, ages 53-55",
    "estimatedCounterfactualEarnings56": "Estimated counterfactual earnings, ages 56-58",
    "estimatedCounterfactualEarnings59": "Estimated counterfactual earnings, ages 59-61",
    "estimatedCounterfactualEarnings62": "Estimated counterfactual earnings, ages 62-64",
    "resultsAvailableForAllSpecifications": "Results available for all specifications"
};